// 全局变量配置

const seeting = window._mode;
const mode = seeting.mode;
seeting.from = seeting.from ? seeting.from : 'hz';
let conf = null;

// if (seeting && seeting.sysout) console.log(seeting);

if (seeting.from === 'gx') {
  conf = {
    jgUrl: "http://gx.food12315.cn/fdav/#/gov/login",
    qyUrl: "http://gx.food12315.cn/fdav/#/login",
    lawRequestUrl: "http://gx.food12315.cn/server/api/support/doc/list/open?page=1&limit=16",
    mainTitle: "河池智慧市场监管云平台",
    jgMiniIcoPath: './img/ma_jg_gx.png',
    qyMiniIcoPath: './img/ma_qy_gx.png'
  };
} else {
  if (mode === 'pro') {
    // 生产环境
    conf = {
      jgUrl: "https://fda.food12315.cn/fdav/#/gov/login",
      qyUrl: "https://fda.food12315.cn/fdav/#/login",
      lawRequestUrl: "https://fda.food12315.cn/server/api/support/doc/list/open?page=1&limit=16",
      mainTitle: "食品安全监管云平台",
      jgMiniIcoPath: './img/ma_jg.png',
      qyMiniIcoPath: './img/ma_qy.png'
    };
  } else if (mode === 'test') {
    // 演示环境
    conf = {
      jgUrl: "https://fda.nkbiotech.com/fdav/#/gov/login",
      qyUrl: "https://fda.nkbiotech.com/fdav/#/login",
      lawRequestUrl: "https://fda.nkbiotech.com/server/api/support/doc/list/open?page=1&limit=16",
      mainTitle: "食品安全监管云平台",
      jgMiniIcoPath: './img/ma_jg.png',
      qyMiniIcoPath: './img/ma_qy.png'
    };
  } else if (mode === '140') {
    // 140
    conf = {
      jgUrl: "http://122.224.196.140:140/fda/index.html#/user/gov/login",
      qyUrl: "http://122.224.196.140:140/fda/index.html#/user/login",
      lawRequestUrl: "http://122.224.196.140:140/server/api/support/doc/list/open?page=1&limit=16",
      mainTitle: "食品安全监管云平台",
      jgMiniIcoPath: './img/ma_jg.png',
      qyMiniIcoPath: './img/ma_qy.png'
    };
  }
}

const urlPaths = conf;
export default urlPaths;
